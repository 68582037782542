import './App.css';
import './cloudflareLogo.png'
import React, { Component } from "react";
import axios from 'axios'
import TrafficChangeChart from "./components/TrafficChangeChart";
import PopularDomainList from "./components/PopularDomainList"
import AttackLayerThreeChart from "./components/AttackLayerThreeChart";


class App extends Component{
    constructor(props) {
        super(props);

        this.state = {
            trafficChange: [],
            popularDomains: [],
            attackLayerThree: []
        }
    }

    componentDidMount() {
        axios.get('https://json.yulinzeng.workers.dev/traffic-change')
            .then(response =>{
                let trafficChangeData = [{}];
                const totalData = response.data.data.total;

                const httpData = response.data.data.http;
                for(let i = 0; i < totalData.timestamps.length; i++){
                    const trafficChangeObj = {
                        timestamp: totalData.timestamps[i],
                        total: totalData.values[i],
                        http: httpData.values[i],
                    };
                    trafficChangeData.push(trafficChangeObj);
                }
                this.setState({trafficChange: trafficChangeData})
            })
            .catch(error => {
                console.log(error)
            })

        axios.get('https://json.yulinzeng.workers.dev/popular-domains')
            .then(response =>{
                this.setState({popularDomains: response.data.rankingEntries})
            })
            .catch(error => {
                console.log(error)
            })

        axios.get('https://json.yulinzeng.workers.dev/attack-layer3')
            .then(response =>{
                let attackLayerThreeData = [{}];
                const totalData = response.data.data.total;
                for(let i = 0; i < totalData.timestamps.length; i++){
                    const trafficChangeObj = {
                        timestamp: totalData.timestamps[i],
                        percentage: totalData.values[i]
                    };
                    attackLayerThreeData.push(trafficChangeObj);
                }
                this.setState({attackLayerThree: attackLayerThreeData})
            })
            .catch(error => {
                console.log(error)
            })
    }

    render(){
        return (
            <div className="App">
                <header className="App-header">
                    <h1>
                        Cloudflare Hiring Assignment
                    </h1>
                    <h2>2023 Summer Intern </h2>
                    <p>
                        Author: Yulin Zeng
                    </p>
                </header>
                <div className="mainContainer">
                    <TrafficChangeChart trafficData={ this.state.trafficChange}/>
                    <PopularDomainList domainData={this.state.popularDomains}/>
                    <AttackLayerThreeChart attackLayerThreeData={this.state.attackLayerThree}/>
                </div>

            </div>
        );
  }
}

export default App