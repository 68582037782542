import React, { Component } from "react";
import '../charts.css'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";

class AttackLayerThreeChart extends Component {
    render() {
        const AttackLayerThreeData = this.props.attackLayerThreeData;
        const data = AttackLayerThreeData;

        return (
            <div>
                <h1>attack-layer3</h1>
                <LineChart
                    width={1000}
                    height={600}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="timestamp" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="percentage"
                        stroke="#8884d8"
                        dot={false}
                    />
                </LineChart></div>
        )
    }
}
export default AttackLayerThreeChart