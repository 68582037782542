import React, { Component } from "react";
import '../charts.css'


class PopularDomainList extends Component {
    render() {
        const domains = this.props.domainData;
        return (
            <div className="mainContainer">
                <h1>popular-domains</h1>
                <table className="blue">
                    <thead>
                        <tr>
                            <td>Rank</td>
                            <td>RankChange</td>
                            <td>Domain</td>
                            <td>Category</td>
                        </tr>
                    </thead>
                    <tbody>
                    {domains.map((domain) => (
                        <tr key={domain.rank}>
                            <td>{domain.rank}</td>
                            <td>{domain.rankChange}</td>
                            <td>{domain.domain}</td>
                            <td>{domain.category}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )
    }
}
export default PopularDomainList