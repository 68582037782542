import React, { Component } from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";

class TrafficChangeChart extends Component {
    render() {
        const trafficChangeData = this.props.trafficData;
        const data = trafficChangeData;

        return (
            <div>
                <h1>traffic-change</h1>
                <LineChart
                    width={1000}
                    height={600}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="timestamp" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="total" stroke="#8884d8" dot={false}/>
                    <Line type="monotone" dataKey="http" stroke="#82ca9d" dot={false}/>
                </LineChart></div>
        )
    }
}
export default TrafficChangeChart